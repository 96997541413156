var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-card", { staticClass: "mb-1", attrs: { "no-body": "" } }, [
        _c("div", { staticClass: "m-2" }, [
          _c("div", { staticClass: "form-row" }, [
            _c("div", { staticClass: "col-md-6" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filters.title,
                      expression: "filters.title",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", placeholder: "Título do post" },
                  domProps: { value: _vm.filters.title },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.filters, "title", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "col-md-6 col-6" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c(
                    "v-select",
                    {
                      attrs: {
                        label: "title",
                        "item-text": "title",
                        "item-value": "code",
                        placeholder: "Concurso",
                        options: _vm.optionsContests,
                      },
                      on: { search: _vm.fetchContests },
                      model: {
                        value: _vm.filters.contest,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "contest", $$v)
                        },
                        expression: "filters.contest",
                      },
                    },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "no-options" }, slot: "no-options" },
                        [_vm._v(" Nenhum registro encontrado ")]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "col-md-3 col-6" }, [
              _c(
                "div",
                { staticClass: "form-group mb-md-0" },
                [
                  _c(
                    "v-select",
                    {
                      attrs: {
                        label: "title",
                        "item-text": "title",
                        "item-value": "code",
                        placeholder: "Área",
                        options: _vm.optionsAreas,
                      },
                      on: { search: _vm.fetchAreas },
                      model: {
                        value: _vm.filters.area,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "area", $$v)
                        },
                        expression: "filters.area",
                      },
                    },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "no-options" }, slot: "no-options" },
                        [_vm._v(" Nenhum registro encontrado ")]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "col-md-5" }, [
              _c(
                "div",
                { staticClass: "form-group mb-md-0" },
                [
                  _c(
                    "v-select",
                    {
                      attrs: {
                        label: "title",
                        "item-text": "title",
                        "item-value": "code",
                        placeholder: "Jornalista",
                        options: _vm.optionsAuthors,
                      },
                      on: { search: _vm.fetchAuthors },
                      model: {
                        value: _vm.filters.author,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "author", $$v)
                        },
                        expression: "filters.author",
                      },
                    },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "no-options" }, slot: "no-options" },
                        [_vm._v(" Nenhum registro encontrado ")]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "col-md-3" }, [
              _c(
                "div",
                { staticClass: "form-group mb-md-0" },
                [
                  _c("flat-pickr", {
                    staticClass: "form-control",
                    attrs: {
                      placeholder: "Período de atualização",
                      config: {
                        mode: "range",
                        altInput: true,
                        dateFormat: "Y-m-d",
                        altFormat: "d/m/Y",
                        locale: "pt",
                      },
                    },
                    model: {
                      value: _vm.filters.updated,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "updated", $$v)
                      },
                      expression: "filters.updated",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "col-md-1" }, [
              _c("div", { staticClass: "form-group mb-md-0" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary btn-block",
                    staticStyle: { padding: "14px !important" },
                    attrs: { type: "button" },
                    on: { click: _vm.search },
                  },
                  [
                    !_vm.btnSearch
                      ? _c("i", { staticClass: "bi bi-search" })
                      : _c("b-spinner", {
                          attrs: { small: "", variant: "light" },
                        }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c(
        "b-card",
        { staticClass: "mb-0", attrs: { "no-body": "" } },
        [
          _c("b-table", {
            staticClass: "position-relative",
            attrs: {
              "sticky-header": "",
              striped: "",
              hover: "",
              items: _vm.dataItems,
              responsive: "",
              fields: _vm.tableColumns,
              "primary-key": "id",
              "show-empty": "",
              "empty-filtered-text": "Nenhum registro encontrado",
              "empty-text": "Nenhum registro encontrado",
            },
            scopedSlots: _vm._u([
              {
                key: "cell(title)",
                fn: function (data) {
                  return [
                    _c(
                      "div",
                      { staticClass: "d-flex-left" },
                      [
                        data.item.image
                          ? _c("b-avatar", {
                              attrs: {
                                rounded: "sm",
                                size: "42",
                                src: data.item.image,
                              },
                            })
                          : _c(
                              "b-avatar",
                              {
                                attrs: {
                                  rounded: "sm",
                                  variant: "light-secondary",
                                },
                              },
                              [
                                _c("feather-icon", {
                                  attrs: { icon: "ImageIcon" },
                                }),
                              ],
                              1
                            ),
                        _c("div", [
                          _c(
                            "a",
                            {
                              staticClass: "d-flex-left ml-75 text-wrap",
                              attrs: {
                                title: data.item.title,
                                href:
                                  "https://" +
                                  _vm.school.domain +
                                  "/noticia/" +
                                  data.item.slug,
                                target: "_blank",
                              },
                            },
                            [_vm._v(" " + _vm._s(data.item.title) + " ")]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "cell(author)",
                fn: function (data) {
                  return [_vm._v(" " + _vm._s(data.item.author) + " ")]
                },
              },
              {
                key: "cell(tags)",
                fn: function (data) {
                  return [
                    _c("b-badge", [
                      _vm._v(
                        " " +
                          _vm._s(
                            data.item.tags
                              ? JSON.parse(data.item.tags).length
                              : ""
                          ) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "cell(created_at)",
                fn: function (data) {
                  return [
                    data.item.updated_at
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("datePT")(data.item.updated_at, true)
                              ) +
                              " "
                          ),
                        ])
                      : _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("datePT")(data.item.created_at, true)
                              ) +
                              " "
                          ),
                        ]),
                  ]
                },
              },
              {
                key: "cell(actions)",
                fn: function (data) {
                  return [
                    _c(
                      "div",
                      { staticClass: "d-flex-between" },
                      [
                        _c("feather-icon", {
                          staticClass: "cursor-pointer cursor text-danger",
                          attrs: { icon: "Trash2Icon", size: "16" },
                          on: {
                            click: function ($event) {
                              return _vm.confirmDelete(data.item.uuid)
                            },
                          },
                        }),
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "blog-posts-edit",
                                params: { uuid: data.item.uuid },
                              },
                            },
                          },
                          [
                            _c("feather-icon", {
                              staticClass: "cursor-pointer cursor",
                              attrs: { icon: "EditIcon", size: "16" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c(
            "div",
            { staticClass: "mx-2 mb-2 mt-2 paginate-area" },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-center justify-content-sm-start",
                      attrs: { cols: "12", md: "6" },
                    },
                    [
                      _c("span", {}, [
                        _vm._v(" Foram encontrados "),
                        _c("strong", [_vm._v(_vm._s(_vm.totalRows))]),
                        _vm._v(" resultados "),
                      ]),
                    ]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-center justify-content-sm-end",
                      attrs: { cols: "12", md: "6" },
                    },
                    [
                      _c("b-pagination", {
                        staticClass: "my-1",
                        attrs: {
                          "total-rows": _vm.totalRows,
                          "per-page": _vm.filters.perPage,
                          limit: 10,
                          "first-number": "",
                          "last-number": "",
                          "prev-class": "prev-item",
                          "next-class": "next-item",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.getData()
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "prev-text",
                            fn: function () {
                              return [
                                _c("feather-icon", {
                                  attrs: {
                                    icon: "ChevronLeftIcon",
                                    size: "18",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "next-text",
                            fn: function () {
                              return [
                                _c("feather-icon", {
                                  attrs: {
                                    icon: "ChevronRightIcon",
                                    size: "18",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.filters.currentPage,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "currentPage", $$v)
                          },
                          expression: "filters.currentPage",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }