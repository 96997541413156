<template>
  <div>
    <b-card no-body class="mb-1">
      <div class="m-2">
        <div class="form-row">
          <div class="col-md-6">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                placeholder="Título do post"
                v-model="filters.title"
              />
            </div>
          </div>

          <div class="col-md-6 col-6">
            <div class="form-group">
              <v-select
                label="title"
                item-text="title"
                item-value="code"
                v-model="filters.contest"
                placeholder="Concurso"
                :options="optionsContests"
                @search="fetchContests"
              >
                <span slot="no-options"> Nenhum registro encontrado </span>
              </v-select>
            </div>
          </div>

          <div class="col-md-3 col-6">
            <div class="form-group mb-md-0">
              <v-select
                label="title"
                item-text="title"
                item-value="code"
                v-model="filters.area"
                placeholder="Área"
                :options="optionsAreas"
                @search="fetchAreas"
              >
                <span slot="no-options"> Nenhum registro encontrado </span>
              </v-select>
            </div>
          </div>

          <div class="col-md-5">
            <div class="form-group mb-md-0">
              <v-select
                label="title"
                item-text="title"
                item-value="code"
                v-model="filters.author"
                placeholder="Jornalista"
                :options="optionsAuthors"
                @search="fetchAuthors"
              >
                <span slot="no-options"> Nenhum registro encontrado </span>
              </v-select>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group mb-md-0">
              <flat-pickr
                v-model="filters.updated"
                class="form-control"
                placeholder="Período de atualização"
                :config="{
                  mode: 'range',
                  altInput: true,
                  dateFormat: 'Y-m-d',
                  altFormat: 'd/m/Y',
                  locale: 'pt',
                }"
              />
            </div>
          </div>

          <div class="col-md-1">
            <div class="form-group mb-md-0">
              <button
                @click="search"
                style="padding: 14px !important"
                type="button"
                class="btn btn-secondary btn-block"
              >
                <i v-if="!btnSearch" class="bi bi-search"></i>
                <b-spinner v-else small variant="light" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-card>

    <b-card no-body class="mb-0">
      <b-table
        sticky-header
        striped
        hover
        class="position-relative"
        :items="dataItems"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-filtered-text="Nenhum registro encontrado"
        empty-text="Nenhum registro encontrado"
      >
        <template #cell(title)="data">
          <div class="d-flex-left">
            <b-avatar
              rounded="sm"
              v-if="data.item.image"
              size="42"
              :src="data.item.image"
            />
            <b-avatar v-else rounded="sm" variant="light-secondary">
              <feather-icon icon="ImageIcon" />
            </b-avatar>

            <div>
              <a
                :title="data.item.title"
                :href="`https://${school.domain}/noticia/${data.item.slug}`"
                target="_blank"
                class="d-flex-left ml-75 text-wrap"
              >
                {{ data.item.title }}
              </a>
            </div>
          </div>
        </template>

        <template #cell(author)="data">
          {{ data.item.author }}
        </template>

        <template #cell(tags)="data">
          <b-badge>
            {{ data.item.tags ? JSON.parse(data.item.tags).length : "" }}
          </b-badge>
        </template>

        <template #cell(created_at)="data">
          <span v-if="data.item.updated_at">
            {{ data.item.updated_at | datePT(true) }}
          </span>
          <span v-else>
            {{ data.item.created_at | datePT(true) }}
          </span>
        </template>

        <template #cell(actions)="data">
          <div class="d-flex-between">
            <feather-icon
              icon="Trash2Icon"
              size="16"
              class="cursor-pointer cursor text-danger"
              @click="confirmDelete(data.item.uuid)"
            />
            <router-link
              :to="{
                name: 'blog-posts-edit',
                params: { uuid: data.item.uuid },
              }"
            >
              <feather-icon
                icon="EditIcon"
                class="cursor-pointer cursor"
                size="16"
              />
            </router-link>
          </div>
        </template>
      </b-table>

      <div class="mx-2 mb-2 mt-2 paginate-area">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="">
              Foram encontrados
              <strong>{{ totalRows }}</strong> resultados
            </span>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="filters.currentPage"
              :total-rows="totalRows"
              :per-page="filters.perPage"
              :limit="10"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="my-1"
              @input="getData()"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BTable,
  BPagination,
  BForm,
  BCardText,
  BFormRow,
  BSpinner,
  BCol,
  BMedia,
  BBadge,
  BAvatar,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { avatarText } from "@core/utils/filter";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";
import moment from "moment";

import { mapState } from "vuex";

export default {
  setup() {
    return {
      avatarText,
    };
  },
  components: {
    BCard,
    BSpinner,
    BBadge,
    BRow,
    flatPickr,
    BCol,
    BTable,
    BPagination,
    vSelect,
    BForm,
    BCardText,
    BFormRow,
    BMedia,
    BAvatar,
  },
  data() {
    return {
      filters: {
        author: "",
        title: "",
        updated: null,
        area: "",
        contest: "",
        currentPage: 1,
        perPage: 100,
      },
      preview: "",
      btnSearch: false,
      textSubmit: "",
      submited: false,
      totalRows: 0,
      rowsTable: 0,
      tableColumns: [
        {
          key: "title",
          label: "Título",
          thStyle: "width: 500px",
          tdClass: "tdTitle",
        },
        {
          key: "tags",
          label: "Tags",
          class: "text-center",
        },
        {
          key: "author",
          label: "Jornalista",
          class: "text-center",
        },
        {
          key: "created_at",
          label: "Data",
          class: "text-center",
        },
        {
          key: "actions",
          label: "",
          thClass: "text-center td-actions",
          tdClass: "text-center",
          thStyle: "width: 80px",
        },
      ],
      dataItems: [],
      optionsAreas: [],
      optionsContests: [],
      optionsAuthors: [],
      uuid: "",
      school: "",
    };
  },
  mounted() {
    this.getData();
  },
  computed: {
    ...mapState("Area", ["searchAreas"]),
  },
  methods: {
    async fetchContests(term) {
      this.optionsContests = [];
      if (term.length > 2) {
        this.$store.dispatch("Contest/search", { term: term }).then((res) => {
          this.optionsContests = res;
        });
      }
    },
    async fetchAreas(term) {
      if (term.length > 2) {
        this.$store.dispatch("Area/search", term).then(() => {
          this.optionsAreas = this.searchAreas;
        });
      }
    },
    async fetchAuthors(term) {
      if (term.length > 2) {
        this.$store.dispatch("Author/search", term).then((res) => {
          this.optionsAuthors = res;
        });
      }
    },
    search() {
      this.btnSearch = true;
      this.getData();
    },
    async getData() {
      this.$store
        .dispatch("Post/all", this.filters)
        .then((res) => {
          if (res) {
            this.dataItems = res.data;
            this.rowsTable = res.data.length;
            this.totalRows = res.meta.total;
            this.filters.currentPage = res.meta.current_page;
          }
        })
        .finally(() => {
          this.btnSearch = false;
        });
    },
    confirmDelete(uuid) {
      this.$swal({
        title: "Quer excluir?",
        text: "Esta ação será irreversível!",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero excluir!",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("Post/delete", uuid).then(() => {
            this.getData();
            this.notifyDefault("success");
          });
        }
      });
    },
  },
  directives: {
    Ripple,
  },
  created() {
    const user = JSON.parse(localStorage.getItem("userData"));
    this.school = user.school;
  },
};
</script>

<style lang="scss">
.vs__selected-options {
  flex-wrap: nowrap;
  max-width: calc(100% - 41px);
}

.vs__selected {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
}

.vs__dropdown-toggle {
  height: 44px;
}

.vs__search {
  position: absolute;
  width: 100%;
}

.vs__selected-options input {
  width: 100%;
}

.vs--open .vs__search {
  position: static;
}

.vs__dropdown-option {
  white-space: normal;
}
</style>
